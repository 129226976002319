import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/SEO"
import { Main } from "../components/UI"
import CookiePage from "../components/CookiePage"

const CookiePolicyPage = ({ data }) => {
  const noIndex = data.allDatoCmsCookiePolicy.nodes[0].noIndex
  const canonicalUrl = `https://thetoucan.app/cookie-policy/`
  return (
    <Layout>
      <Seo
        title="Terms and Conditions - Toucan"
        noIndex={noIndex}
        canonicalUrl={canonicalUrl}
      />
      <Main className="overflow-hidden bg-white">
        <CookiePage />
      </Main>
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsCookiePolicy {
      nodes {
        noIndex
      }
    }
  }
`

export default CookiePolicyPage
