import { useStaticQuery, graphql } from "gatsby"

const useCookiePolicyData = () => {
  const data = useStaticQuery(graphql`
    {
      allDatoCmsCookiePolicy {
        nodes {
          title
          body {
            value
            links
            blocks
          }
          meta {
            updatedAt
          }
        }
      }
    }
  `)
  return data.allDatoCmsCookiePolicy.nodes[0]
}

export default useCookiePolicyData
